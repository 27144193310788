@import '@immosolve/empro-shared-ui/inter-font.scss';
@import '@immosolve/empro-shared-ui/styles.scss';

html {
  @apply bg-secondary-50 h-full;
}

select,
option {
  font-family: Inter, Arial, sans-serif;
}

.wrapper {
  max-width: initial !important;
}

a {
  @apply cursor-pointer;
}
